import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ArianeeService } from '../arianee-service/arianee.service';
import { finalize, first, map, mergeMap, retryWhen } from 'rxjs/operators';
import { ArianeeWallet } from '@arianee/arianeejs/dist/src/core/wallet';
import { Observable, ReplaySubject, throwError, timer } from 'rxjs';
import { POAPEvent, POAPNFT } from '@arianeeprivate/wallet-shared-components';
import { IdentityBase } from '@arianee/arianeejs/dist/src/models';
import { environment } from '../../../environments/environment';
import { ModalController } from '@ionic/angular';
import { PoapErrorModalComponent } from '../../components/poap-error-modal/poap-error-modal.component';
import { OverlayEventDetail } from '@ionic/core';

export interface PoapClaimResponse {
  beneficiary: string;
  claimed: boolean;
  'claimed_date': string;
  'created_date': string;
  'delegated_mint': boolean;
  'delegated_signed_message': string;
  event: POAPEvent,
  'event_id': number;
  id: number;
  'is_active': boolean;
  'qr_hash': string;
  'queue_uid': string;
  signer: string;
  'user_input': string;
}

export interface PoapQueueStatusResponse {
  operation: string;
  result: any;
  status: string;
  uid: string;
}

@Injectable({
  providedIn: 'root'
})
export class PoapService {
    public readonly poapAddress = '0x773211AaCfde8782Ba241fddb471925B4A41d209';
    private $poapIdentity = new ReplaySubject<IdentityBase>(1);
    public $poapList = new ReplaySubject<POAPNFT[]>(1);

    constructor (
        private http: HttpClient,
        private walletService: ArianeeService,
        private modalCtrl: ModalController
    ) {
    }

    /**
     * Check if poap is a poap link (pattern)
     * ex: http:// POAP.xyz/claim/6obhif => true
     * ex: http://POAP.xyz/notCLAIM/6obhif => false
     * ex: http:// google.xyz/claim/6obhif => false
     * @param link
     */
    public isPOAPLink = (link: string): boolean => {
      try {
        const url = new URL(link);
        const isPOAPHost = url.host.toLowerCase() === 'poap.xyz';
        const isClaimParam = url.pathname.startsWith('/claim/');

        return isPOAPHost && isClaimParam;
      } catch (e) {
        return false;
      }
    };

    /**
     * Get qr hash from a link
     * ex: http:// POAP.xyz/claim/6obhif => 6obhif
     * @param link
     */
    public getQrHashFromLink = (link: string) => {

      if (this.isPOAPLink(link)) {
        const splitted = link.split('/claim/');

        return splitted[splitted.length - 1];
      }
      throw new Error('it is not a poap url');
    }

    /**
     * Get poap nft directly from link
     * ex: http:// POAP.xyz/claim/6obhif
     * @param link
     */
    public getPOAPNFTfromPOAPLink = (link: string) => {
      const qrhash = this.getQrHashFromLink(link);
      return this.getNFTFromPoapQrHash(qrhash);
    }

    /*************************************************************************/
    /**
     * Get poap Arianee Identity
     */
    getPoapIdentity () {
      const identity: string | null = localStorage.getItem('poap-identity');

      if (identity) {
        this.$poapIdentity.next(JSON.parse(identity));
      }

      this.fetchPoapIdentity();
      return this.$poapIdentity;
    }

    storePoapIdentity (identity: IdentityBase): void {
      localStorage.setItem('poap-identity', JSON.stringify(identity));
      this.$poapIdentity.next(identity);
    }

    fetchPoapIdentity (): void {
      const url = 'https://api.arianee.net/identity/polygon/0x773211AaCfde8782Ba241fddb471925B4A41d209';
      this.http.get(url)
        .pipe(
          retryWhen(this.genericRetryStrategy({})),
          map((data) => {
            const identity = { data, address: this.poapAddress } as IdentityBase;
            this.storePoapIdentity(identity);
            return identity;
          })
        ).subscribe({
          error: async () => {
            const response = await this.openMaxRetryAlert();
            if (response.retry) {
              this.fetchPoapIdentity();
            }
          }
        });
    }
    /*************************************************************************/

    /**
     * Get list of poap nft of this wallet
     */
    getMyPoapNfts () {
      const poapList: string | null = localStorage.getItem('poap-list');
      if (poapList) {
        this.$poapList.next(JSON.parse(poapList));
      }
      this.fetchWalletPoapNfts();
      return this.$poapList;
    }

    storePoapNfts (list: POAPNFT[]): void {
      localStorage.setItem('poap-list', JSON.stringify(list));
      this.$poapList.next(list);
    }

    fetchWalletPoapNfts (): void {

      this.walletService.$wallet.pipe(
        first(),
        mergeMap(
          (data: ArianeeWallet) => {
            return this.http.get<POAPNFT[]>(`${environment.poap.url}/actions/scan/${data.address}`)
              .pipe(
                retryWhen(this.genericRetryStrategy({})),
                map((data: POAPNFT[]) => {
                  this.storePoapNfts(data);
                  return data;
                })
              );
          }
        )
      ).subscribe({
        error: async () => {
          const response = await this.openMaxRetryAlert();
          if (response.retry) {
            this.fetchWalletPoapNfts();
          }
        }
      });
    }
    /*************************************************************************/

    /**
     * Get a NFT from qr hash
     * You must be careful and save the "secret" as it will be needed to claim POAP
     * @param qrHash
     */
    public getNFTFromPoapQrHash = (qrHash: string): Observable<POAPNFT> => {
      const url = `${environment.poap.url}/actions/claim-qr`;

      return this.http.get<POAPNFT>(url, {
        params: {
          qr_hash: qrHash
        }
      }).pipe(
        retryWhen(this.genericRetryStrategy({}))
      );
    }

    /**
     * Get a NFT from qr hash
     * You must be careful and save the "secret" as it will be needed to claim POAP
     * @param qrHash
     */
    public getPOAPByTokenId = (tokenId: string): Observable<POAPNFT> => {
      const url = `${environment.poap.url}/token/${tokenId}`;
      return this.http.get<POAPNFT>(url)
        .pipe(
          retryWhen(this.genericRetryStrategy({}))
        );
    }

    /**
     * Is address has poap of this event and if yes which one
     * check if an address holds a token for a specific event
     */

    public async findTokenIdOfEventIdOfAddress (parameters: { eventId: string }): Promise<string | null> {
      const { address } = await this.walletService.$wallet.pipe(first()).toPromise();
      const url = `${environment.poap.url}/actions/scan/${address}/${parameters.eventId}`;
      try {
        const result = await this.http.get<POAPNFT>(url).toPromise();
        return result.tokenId.toString();
      } catch (e) {
        return undefined;
      }
    }

    // b9595849-167e-48ca-83ef-a4b9f050a427

    public getQueueStatus = (queueID): Observable<PoapQueueStatusResponse> => {
      const url = `${environment.poap.url}//queue-message/${queueID}`;
      return this.http.get<PoapQueueStatusResponse>(url);
    }

    /**
     * Claim poap nft
     */
    public claimPOAP (parameters: { 'qr_hash': string, secret: string }): Observable<PoapClaimResponse> {
      const url = `${environment.poap.url}/actions/claim-qr`;

      return this.walletService.$wallet.pipe(
        first(),
        mergeMap(
          (data: ArianeeWallet) => {
            return this.http.post<PoapClaimResponse>(url, {
              ...parameters,
              address: data.address
            });
          }
        )
      );
    }

  /**
   * Custom Retry Function for Rxjs
   */
  genericRetryStrategy = (
    { maxRetryAttempts = 3, delay = 2000, excludedStatusCodes = [] }:
      { maxRetryAttempts?: number, delay?: number, excludedStatusCodes?: number[] }
  ) => (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((error, i: number) => {
        const retryAttempt = i + 1;
        if (
          retryAttempt > maxRetryAttempts ||
          excludedStatusCodes.find(e => e === error.status)
        ) {
          return throwError(error);
        }
        // retry after delay value
        return timer(delay);
      }),
      finalize(() => {})
    );
  };

  async openMaxRetryAlert (): Promise<{ retry: boolean }> {
    const detailModal = await this.modalCtrl.create({
      component: PoapErrorModalComponent,
      cssClass: 'modal-wallet-connect-style',
      backdropDismiss: false,
      componentProps: {
        title: 'poapErrorModal.title',
        message: 'poapErrorModal.message'
      }
    });

    await detailModal.present();
    const response:OverlayEventDetail<boolean> = await detailModal.onDidDismiss();
    // IF cancel: data = false
    // IF Retry: data = true
    return Promise.resolve({ retry: response.data });
  }
}
