import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ArianeeService } from '../arianee-service/arianee.service';
import { map, mergeMap, shareReplay, skip, switchMap, take, tap } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { forkJoin, from, Observable, of, pipe, ReplaySubject, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import moment from 'moment';
import { messages as mockMessagesFromBatch } from './messaging.mock';
import { StorageService } from '../storage-service/storage.service';
import { NativeStorage } from '@ionic-native/native-storage/ngx';

import { CertificateSummary } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary';
//import { BaiduResponse, NotificationData } from '@ionic-native/baidu-push/ngx';
import {
  DisplayMapperService,
  EnrichedNotification,
  InboxNotification,
  Notification,
  NotificationProvider,
  PushNotification,
  ToasterService
} from '@arianeeprivate/wallet-shared-components';

declare var batch;

@Injectable({
  providedIn: 'root'
})
export class MessagingService implements NotificationProvider {
  private notifications: EnrichedNotification[] = [];
  private nativeStorage: NativeStorage;

  public $message = new ReplaySubject<EnrichedNotification[]>(1)

  public $certificatesId:Observable<any>=this.arianeeService
    .methods
    .pipe(
      mergeMap(methods => methods.getMyCertificates({ content: false })),
      shareReplay(1)
    )

  constructor (private httpClient: HttpClient,
               private arianeeService: ArianeeService,
               private platform: Platform,
               private storageService: StorageService,
               private toasterService: ToasterService,
              private displayMapperService:DisplayMapperService
  ) {
    this.nativeStorage = storageService.nativeStorage;
    this.refreshOnTransfer();
  }

  public batchPushNotificationHandler (payload) {
    const notif = this.mapPushMessage(payload);
    this.storeNotification(notif)
      .pipe(
        mergeMap((isStore) => {
          if (isStore) {
            return this.enrichedNotification(notif);
          } else {
            return of(null);
          }
        }),
        take(1)
      )
      .subscribe((notification) => {
        if (this.platform.is('ios')) {
          this.toasterService.show({
            header: 'Notification.newMessage',
            message: notification.title,
            position: 'top',
            duration: 5000
          });
        }

        this.pushNotifications([notification]);
      });
  }

  public baiduPushNotificationHandler () {
    /*
    return pipe(
      map((notification:BaiduResponse<NotificationData>) => this.mapBaiduMessage(notification.data)),
      mergeMap((notification: Notification) => this.storeNotification(notification)),
      mergeMap(notification => {
        if (notification !== false) {
          return this.enrichedNotification(notification as Notification);
        }
        return of(false);
      }),
      map((notification) => {
        if (notification !== false) {
          this.pushNotifications([notification as Notification]);
        }
        return of();
      })
    );
    */
  }

  refreshOnTransfer () {
    this.arianeeService.$wallet
      .pipe(
        skip(1),
        mergeMap(() => this.getNotifications().pipe(take(1))),
        mergeMap(notifs => this.filterNotificationByCertificate(notifs))
      )
      .subscribe(notifications => {
        this.$message.next(notifications);
      });
  }

  private keyBuilder = (namespace:string, key) => {
    return this.arianeeService.$wallet
      .pipe(
        map((wallet) => `${wallet.publicKey}/${wallet.configuration.chainId}/${namespace}/${key}`)
      );
  };

  public markAsRead (tokenId, messageId):Observable<any> {
    return from(this.nativeStorage.keys())
      .pipe(
        map((keys) => { return keys.filter(key => (key.match('notification/' + tokenId) && key.match('notification/' + tokenId).length > 0)); }),
        mergeMap((filteredKeys) => {
          return forkJoin(
            [...filteredKeys.map(key => this.editNotifications(key, { isRead: true }))]
          );
        })
      );
  }

  private editNotifications = (notificationsKey, newValue) => {
    return from(this.nativeStorage.getItem(notificationsKey))
      .pipe(
        map(notifications => ({ ...notifications, ...newValue })),
        mergeMap((notifications) => {
          return this.nativeStorage.setItem(notificationsKey, notifications);
        })
      );
  }

  private storeNotification = (notification:Notification) => {
    return this.keyBuilder('notification', notification.tokenId + '/' + notification.id)
      .pipe(
        take(1),
        mergeMap(async (storeKey) => {
          const item = await this.nativeStorage.getItem(storeKey).catch(err => undefined);
          if (!item) {
            from(this.nativeStorage.setItem(storeKey, notification));
            return notification;
          } else {
            return false;
          }
        })
      );
  }

  private getNotifications () {
    return from(this.nativeStorage.keys())
      .pipe(
        take(1),
        map((keys) => { return keys.filter(key => (key.match('notification') && key.match('notification').length > 0)); }),
        mergeMap((filteredKeys) => { return from(Promise.all(filteredKeys.map((noti) => this.nativeStorage.getItem(noti)))); }),
        mergeMap(notifs => {
          if (notifs.length > 0) {
            return forkJoin(
              [...notifs.map((notification:Notification) => this.enrichedNotification(notification))]
            );
          } else {
            return of([]);
          }
        })
      );
  }

  public pushNotifications (notifications: Notification[], forceRefresh = false) {
    let allNotifications;

    if (!forceRefresh) {
      allNotifications = [...this.notifications, ...notifications].filter(notifs => notifs !== null);
    } else {
      allNotifications = notifications.filter(notifs => notifs !== null);
    }

    this.notifications = allNotifications;
    this.$message.next(this.notifications);
  }

  public init () {
    if (this.shouldBeMock()) {
      console.warn('messages and notification are mocked');
      const mappedNotifs = mockMessagesFromBatch.map(notif => this.mapIOSAndroidMessage(notif));
      this.filterNotificationByCertificate(mappedNotifs)
        .subscribe((mappedNotifs:Notification[]) => {
          return this.pushNotifications(mappedNotifs);
        });
    } else {
      if (environment.isBaidu) {
        this.initBaidu();
      } else {
        this.initBatch();
      }
    }
  }

  async initBaidu () {
    /*
    this.arianeeService.$publicKey
      .pipe(
        mergeMap(() => this.getNotifications())
      )
      .subscribe((notifications:Notification[]) => {
        this.pushNotifications(notifications, true);
      });
    */
  }

  initBatch () {
    this.setAddress()
      .pipe(
        mergeMap(publicKey => this.getBatchIdFromPublicKey()),
        mergeMap(batchId => this.fetchMessages(batchId)),
        mergeMap((notifs) => {
          if (!notifs) {
            return of([]);
          }
          return of(notifs.map(notif => this.mapIOSAndroidMessage(notif)));
        }),
        mergeMap((notifs) => this.filterNotificationByCertificate(notifs)),
        mergeMap(notifs => {
          if (notifs.length > 0) {
            return forkJoin(
              [...notifs.map(notification => this.storeNotification(notification))]
            );
          } else {
            return of([]);
          }
        }),
        mergeMap(() => this.getNotifications())
      )
      .subscribe((notifications:Notification[]) => {
        this.pushNotifications(notifications, true);
      });
  }

  private filterNotificationByCertificate (notifs) {
    return this.$certificatesId
      .pipe(
        take(1),
        map(certificates => certificates.map(certificate => certificate.certificateId.toString())),
        map(certificatedIds => notifs.filter(notif => certificatedIds.includes(notif.tokenId.toString())))
      );
  }

  private enrichedNotification (notification): Observable<EnrichedNotification> {
    const { tokenId } = notification;
    return this.arianeeService.methods
      .pipe(
        mergeMap(method => method.getCertificate(tokenId, undefined, { content: true, issuer: true })),
        take(1),
        map((certificate:CertificateSummary) => this.displayMapperService.mapEnrichedNotification(notification, certificate))
      );
  }

  private shouldBeMock () {
    return this.platform.is('desktop') || this.platform.is('mobileweb');
  }

  private fetchMessages (batchId: string): Observable<InboxNotification[]> {
    const $notifications = new Subject<InboxNotification[]>();

    return this.arianeeService.$publicKey
      .pipe(
        tap(publicKey => {
          batch.inbox.fetchNotificationsForUserIdentifier(publicKey, batchId, (err, notifications) => {
            $notifications.next(notifications);
          });
        }),
        mergeMap(i => $notifications)
      );
  }

  private setAddress (): Observable<string> {
    return this.arianeeService.$publicKey
      .pipe(tap((publicKey) => {
        batch.user.getEditor()
          .setIdentifier(publicKey)
          .save();
      })
      );
  }

  private getBatchIdFromPublicKey (): Observable<string> {
    const isAndroid = this.platform.is('android');

    return this.arianeeService.$publicKey
      .pipe(
        switchMap(publicKey => this.httpClient.get<any>(this.pushUserIdUrlCreator(publicKey, isAndroid))),
        map(batchObj => batchObj.batch_id)
      );
  }

  private pushUserIdUrlCreator = (publicKey: string, isAndroid) =>
    `https://tools.arianee.org/batch_user.php?USER_ID=${publicKey}&ANDROID=${isAndroid.toString()}`;

  private mapIOSAndroidMessage (inboxNotification: InboxNotification): Notification {
    const { payload, date } = inboxNotification;
    if (this.platform.is('android') || this.shouldBeMock()) {
      return {
        time: moment(date).toISOString(),
        tokenId: payload.tokenId,
        message: payload.content,
        title: payload.title,
        isRead: false,
        issuer: '',
        id: (typeof payload['com.batch'] === 'string') ? JSON.parse(payload['com.batch']).i : payload['com.batch'].i
      };
    } else if (this.platform.is('ios')) {
      return {
        time: moment(date).toISOString(),
        tokenId: payload.tokenId,
        message: payload.content,
        title: payload.aps.alert.title,
        isRead: false,
        issuer: '',
        id: (typeof payload['com.batch'] === 'string') ? JSON.parse(payload['com.batch']).i : payload['com.batch'].i
      };
    }
  }

  private mapPushMessage (pushNotification:PushNotification):Notification {
    if (this.platform.is('android') || this.shouldBeMock()) {
      return {
        tokenId: pushNotification.tokenId,
        time: moment(parseInt(pushNotification['google.sent_time'])).toISOString(),
        message: pushNotification.content,
        title: pushNotification.title,
        isRead: false,
        issuer: '',
        id: (typeof pushNotification['com.batch'] === 'string') ? JSON.parse(pushNotification['com.batch']).i : pushNotification['com.batch'].i
      };
    } else if (this.platform.is('ios')) {
      return {
        tokenId: pushNotification.tokenId,
        time: moment().toISOString(),
        message: pushNotification.content,
        title: pushNotification.aps.alert.title,
        isRead: false,
        issuer: '',
        id: (typeof pushNotification['com.batch'] === 'string') ? JSON.parse(pushNotification['com.batch']).i : pushNotification['com.batch'].i
      };
    }
  }

  /*
  private mapBaiduMessage (baiduMessage:NotificationData):Notification {
    const properties = JSON.parse(baiduMessage.customContentString);
    return {
      tokenId: '' + properties.tokenId,
      time: moment(properties.sendDate).toISOString(),
      message: baiduMessage.description,
      title: baiduMessage.title,
      isRead: false,
      issuer: '',
      id: properties.id
    };
  }
  */
}
