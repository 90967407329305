// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { defaultDevEnvironment } from './default/environment.default.dev';
import { featureFlipCommonDefautDEV } from './featureFlips/featureFlip.default.dev';

export const environment = {
  ...defaultDevEnvironment,
  name: 'papeclement pwa',
  appType: 'web',
  isBaidu: false,
  batchAPI: { androidAPIKey: '', iOSAPIKey: '' },
  backupSecret: '',
  passphraseSecret: '',
  features: {
    waitingIdentity: false,
    arianeeEvent: true
  },
  slack: {
    apikey: ''
  },
  webIdGoogleDrive: '',
  featureFlips: featureFlipCommonDefautDEV([
    {
      name: 'callFaucetOnLaunch',
      activated: false,
      description: 'request poa faucet on call'
    },
    {
      name: 'blockChainWatcherEventOff',
      activated: true,
      description: 'turn OFF blochain event'
    }
  ])
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
