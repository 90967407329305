import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { filter, map, mergeMap, shareReplay, take, tap } from 'rxjs/operators';
import { FeatureFlipService } from '../feature-flip-service/feature-flip.service';
import { DecentralizedMessageService } from '../decentralized-message/decentralizedMessage.service';
import { MessagingService } from '../messaging-service/messaging-service';
import { ArianeeService } from '../arianee-service/arianee.service';
import { environment } from '../../../environments/environment';
//import { BaiduPush } from '@ionic-native/baidu-push/ngx';
import { ProfileService } from '../profile-service/profile.service';
import { EnrichedNotification } from '@arianeeprivate/wallet-shared-components';

declare var batch;
@Injectable({
  providedIn: 'root'
})

export class NotificationService {
    public $certificatesId: Observable<any> = this.arianeeService
      .methods
      .pipe(
        mergeMap(methods => methods.getMyCertificates({ content: false })),
        shareReplay(1)
      );

    private messageSystem;
    private _$notifications: ReplaySubject<EnrichedNotification[]>;
    private $isInit = new BehaviorSubject(false);
    public $notifications = this.$isInit.pipe(
      filter(isInit => isInit === true),
      mergeMap(isInit => this._$notifications),
      map(notifs => notifs.filter(notif => notif !== null && notif !== undefined)),
      mergeMap(notifs => this.filterNotificationByCertificate(notifs)),
      shareReplay(1)
    )

    constructor (
        private featureFlipService: FeatureFlipService,
        private decentralizedMessage: DecentralizedMessageService,
        private messagingService: MessagingService,
        private arianeeService: ArianeeService,
        //private baiduPush: BaiduPush,
        private profileService:ProfileService
    ) {
    }

    init () {
      this.featureFlipService.$isFeatureFlip('decentralizedMessage')
        .subscribe(async (isFeatureFlipped) => {
          if (isFeatureFlipped) {
            this.messageSystem = this.decentralizedMessage;
          } else {
            this.messageSystem = this.messagingService;
          }
          this._$notifications = this.messageSystem.$message;

          if (environment.isBaidu) {
            await this.initBaidu();
          } else {
            await this.initBatch();
          }

          this.messageSystem.init();

          this.$isInit.next(true);
        });
    }

    public $notificationFilterByCertificateId (certificateId): Observable<EnrichedNotification[]> {
      return this.$notifications.pipe(
        map((notifications) => notifications.filter(notifs => notifs.tokenId === certificateId))
      );
    }

    public $notificationUnreadFilterByCertificateId (certificateId): Observable<EnrichedNotification[]> {
      return this.$notifications.pipe(
        map((notifications) => notifications.filter(notifs => (notifs.tokenId === certificateId && !notifs.isRead)))
      );
    }

    public $getUnreadNotification (): Observable<EnrichedNotification[]> {
      return this.$notifications.pipe(
        map((notifications) => notifications.filter(notifs => !notifs.isRead))
      );
    }

    public markAsRead (tokenId, messageId) {
      const update = () =>
        this.$getUnreadNotification()
          .pipe(
            tap((unreadNotification: EnrichedNotification[]) => {
              if (unreadNotification.length === 0) {
                batch.push.clearBadge();
              }
            }),
            mergeMap(() => this.messageSystem.getNotifications()),
            tap(notifications => this.messageSystem.pushNotifications(notifications, true))
          );

      this.messageSystem.markAsRead(tokenId, messageId)
        .pipe(
          mergeMap(hasBeenModified => {
            if (hasBeenModified === true) {
              return update();
            } else {
              return of([]);
            }
          }),
          take(1)
        )
        .subscribe((notifications) => {

        });
    }

    private async initBaidu () {
      /*
      await this.baiduPush.startWork(environment.baiduAPI.apiKey);
      await this.arianeeService.$publicKey
        .pipe(
          mergeMap(publicKey => this.baiduPush.setTags([publicKey])),
          take(1)
        )
        .toPromise();

      this.profileService
        .getProfile()
        .subscribe(profile => {
          Object.keys(profile).forEach(key => {
            return batch.user.getEditor().setAttribute(key, profile[key]);
          });
        });

      await this.baiduPush.onNotificationArrived()
        .pipe(
          this.messageSystem.baiduPushNotificationHandler()
        )
        .toPromise();
      */
    }

    private async initBatch (): Promise<void> {
      try {
        batch.setConfig(environment.batchAPI);
        batch.start();
        batch.push.registerForRemoteNotifications();

        const publicKey = await this.arianeeService
          .$publicKey
          .pipe(take(1))
          .toPromise();

        await batch.user.getEditor()
          .setIdentifier(publicKey)
          .save();

        document.addEventListener('batchPushReceived', (e: any) => {});
      } catch (e) {

      }
    }

    private filterNotificationByCertificate (notifs) {
      return this.$certificatesId
        .pipe(
          take(1),
          map(certificates => certificates.map(certificate => certificate.certificateId.toString())),
          map(certificatedIds => notifs.filter(notif => certificatedIds.includes(notif.tokenId.toString())))
        );
    }

    public addMessage (messageId) {
      return this.messageSystem.addMessage(messageId)
        .pipe(take(1))
        .toPromise();
    }
}
