import { unionFeatureFlip } from './helpers/unionEnvironment';

export const defaultDEVFeatureFlip = [
  {
    name: 'resell',
    activated: true,
    description: 'resell feature'
  },
  {
    name: 'decentralizedMessage',
    activated: true,
    description: 'decentralized message feature'
  },
  {
    name: 'newBackup',
    activated: true,
    description: 'new backup feature'
  },
  {
    name: 'googleBackup',
    activated: true,
    description: 'google backup'
  },
  {
    name: 'callFaucetOnLaunch',
    activated: true,
    description: 'request poa faucet on call'
  },
  {
    name: 'demoPassport',
    activated: true,
    description: 'display demo passport link'
  },
  {
    name: 'blockChainWatcherEventOff',
    activated: false,
    description: 'turn OFF blochain event'
  },
  {
    name: 'deletePassport',
    activated: true,
    description: 'delete a passport'
  },
  {
    name: 'brandsList',
    activated: false,
    description: 'add brand list in tab'
  },
  {
    name: 'fakeLogin',
    activated: false,
    description: 'display fake login'
  },
  {
    name: 'edgyn',
    activated: false,
    description: 'display Edgyn scan'
  },
  {
    name: 'hideNetworkBanner',
    activated: false,
    description: 'always hide network banner'
  },
  {
    name: 'lost',
    activated: false,
    description: 'lost or stolen feature'
  },
  {
    name: 'walletConnect',
    activated: true,
    description: 'Wallet Connect feature'
  },
  {
    name: 'scanFullScreen',
    activated: true,
    description: 'new scan page'
  },
  {
    name: 'exchange',
    activated: true,
    description: 'exchange'
  }
];
export const featureFlipCommonDefautDEV = unionFeatureFlip(defaultDEVFeatureFlip);
