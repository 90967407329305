import { Injectable } from '@angular/core';
import { ArianeeService } from '../../arianee-service/arianee.service';
import { LoaderService, ToasterService } from '@arianeeprivate/wallet-shared-components';
import { NavController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { DeepLinkObject } from '../../../models/deepLinkObject';
import { catchError, first, map, mergeMap, take, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { isLinkValid } from './helpers/isLinkValid';

@Injectable({
  providedIn: 'root'
})
export class HandleCertificateLinkService {
  constructor (private arianeeService: ArianeeService,
                private toasterService: ToasterService,
                private loaderService: LoaderService,
                private navCtrl: NavController,
                private httpClient: HttpClient
  ) {

  }

    public transformAnyLinkIntoDeepLinkObject = (link: string): Observable<DeepLinkObject> => {
      return this.arianeeService.$wallet
        .pipe(
          take(1),
          map((wallet) => {
            const deeplinkObject = wallet.utils.readLink(link, false);

            if (!isLinkValid(deeplinkObject)) {
              throw new Error('this is not an arianee link');
            }
            return { ...deeplinkObject, deeplink: link };
          }),
          catchError(e => this.tryRedirectToNFT(link)),
          catchError(err => {
            console.info('handleLink ## not a arianee link');
            return this.tryHandlePartnerLink(link);
          })
        );
    };

    /**
     * Try to read an arianee link from any url: https://www.breitling.com/1243,eever
     * @param link
     */
    public tryReadCertificateFromURLShape (link: string): Observable<DeepLinkObject> {
      return this.arianeeService.$wallet
        .pipe(
          first(),
          mergeMap(wallet => {
            try {
              const linkObject = wallet.utils.readLink(link, false);
              if (linkObject.passphrase && linkObject.certificateId) {
                console.info('handleLink ##  trying fetching certificate blindly');
                return of(linkObject);
              }
            } catch (e) {
              throw new Error('this is not an arianee link');
            }
          }));
    }

    /**
     * Try to fetch redirectToNFT
     * @param url
     */
    public tryRedirectToNFT (url:string):Observable<DeepLinkObject> {
      return this.arianeeService.$wallet
        .pipe(take(1))
        .pipe(
          mergeMap(wallet => wallet.methods.createActionArianeeAccessTokenLink(url, -1)),
          mergeMap(urlToFetch => {
            return this.httpClient.get(urlToFetch,
              { responseType: 'text' });
          }
          ),
          withLatestFrom(this.arianeeService.$wallet),
          map(([link, wallet]) => ({
            ...wallet.utils.readLink(link, false),
            deeplink: link
          })
          )
        );
    }

    /**
     * Check if link is a partner link. Ex Bretiling Warranty card
     * @param link
     */
    public tryHandlePartnerLink (link: string): Observable<DeepLinkObject> {
      return this.arianeeService.methodsOnce
        .pipe(
          take(1),
          mergeMap((methods) => {
            return methods.createWalletAccessToken();
          }),
          mergeMap((accessToken) => {
            return this.httpClient.post<DeepLinkObject>(
              environment.partnersLinks,
              { link },
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`
                }
              }
            );
          }),
          map(deepLinkObject => {
            if (isLinkValid(deepLinkObject)) {
              return deepLinkObject;
            } else {
              throw new Error('not a a partner link');
            }
          })
        );
    }

    /**
     * Simple read link from: https://arian.ee/123,234.
     * Auto switch to the right network if it can
     * @param link
     */
    public tryReadLink = (link: string): Observable<DeepLinkObject> => {
      return this.arianeeService.$wallet
        .pipe(first(),
          mergeMap(wallet => {
            try {
              const linkObject = wallet.utils.readLink(link);
              return of(linkObject);
            } catch (e) {
              if (e.chain) {
                console.info('handleLink ##  Switching chain');
                return this.arianeeService.switchEnvironment(e.chain)
                  .pipe(
                    take(1),
                    mergeMap(() => this.tryReadLink(link))
                  );
              } else {
                return this.tryReadCertificateFromURLShape(link);
              }
            }
          }
          ));
    }
}
